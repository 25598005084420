<template>
  <div id="app_signup">
    <v-layout wrap justify-center>
      <v-flex xs12 md12 pa-8>
        <v-card id="app_height" outlined tile>
          <v-layout pb-12 xs12 sm12 md12 wrap>
            <v-flex>
              <v-layout wrap pt-12 justify-center>
                <v-flex xs12 md8 text-center>
                  <span class="login_head">
                    <img
                      src="../../assets/abbLabel.jpg"
                      width="300px"
                      height="150px"
                      class="mt-2"
                  /></span>
                </v-flex>
              </v-layout>
              <v-layout wrap  justify-center>
                <v-flex xs12 md8 text-center>
                  <span class="login_head" ><b>Log in</b></span>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center pt-0>
                <v-flex xs12 sm6 md3 pt-5 px-2>
                  <v-text-field
                    ref="userName"
                    v-model="username"
                    label="Username"
                    :rules="[rules.required]"
                    :error-messages="msg_username"
                    required
                  ></v-text-field>
                  <v-text-field
                    ref="password"
                    v-model="password"
                    label="Password"
                    type="password"
                    :rules="[rules.required]"
                    :error-messages="msg_password"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout v-if="msg" wrap justify-center>
                <v-flex xs12 md3 pa-2 text-center>
                  <span style="font-size: 14px; color: red">{{ msg }}</span>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center>
                <v-flex xs12 md12 pt-2 text-center>
                  <v-btn
                    rounded
                    color="primary"
                    width="250px"
                    class="text-capitalize"
                    dark
                    small
                    :ripple="true"
                    @click="login"
                    >Log In</v-btn
                  >
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-center>
          <v-flex xs12 md3 pt-5 text-center>
            <router-link to="/ForgotPassword">
              <span style="font-size:14px;">Forgot Password?</span>
            </router-link>
          </v-flex>
        </v-layout> -->
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
// import Login from "@/components/Login/loginMain.vue";
export default {
  components: {
    // Login
  },

  data() {
    return {
      appLoading: false,
      username: null,
      password: null,

      msg: null,
      msg_username: null,
      msg_password: null,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  methods: {
    login() {
      this.appLoading = true;
      var logindata = {};
      logindata["email"] = this.username;
      logindata["password"] = this.password;
      axios({
        method: "post",
        url: "/user/signin",
        data: logindata,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status === true) {
            store.commit("loginUser");
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("role", response.data.role);
            this.appLoading = false;
            this.$router.push({ path: "/dashboard" });
            this.getProfile();
          } else {
            if (response.data.msg == "user not found.") {
              this.msg_username = response.data.msg;
              this.msg_password = null;
            } else {
              this.msg_password = response.data.msg;
              this.msg_username = null;
            }
          }
        })
        .catch((error) => {
          this.msg = error;
          this.errored = true;
          this.appLoading = false;
        });
    },
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.userData = response.data.data;
            if (this.userData.agentCode) {
              localStorage.setItem("agentCode", this.userData.agentCode);
            } else localStorage.setItem("agentCode", "");
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.login_head {
  font-family: opensans;
  font-size: 25px;
  font-weight: 400;
}
#app_signup {
  background-color: #2c3341;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 90vh;
  }
}
</style>